// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/MoonTime/MoonTime-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../fonts/MoonTime/MoonTime-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../shared/img/eloise.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'MoonTime';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  font-weight: normal;\n  font-style: normal;\n}\n.about-link {\n  color: #000000;\n  text-decoration: none;\n}\n.about-links {\n  display: flex;\n  justify-content: space-evenly;\n}\n.about-links .link-icon {\n  margin: 0 5px;\n}\n.eloise {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-size: cover;\n  background-position: center;\n  float: left;\n  height: 250px;\n  width: 170px;\n  margin: 20px 10px 0 0;\n}\n.moontime-font {\n  font-family: \"MoonTime\";\n  font-size: 50px;\n}\n", "",{"version":3,"sources":["webpack://./src/fonts/MoonTime/moontime.less","webpack://./src/pages/About/index.less"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,qFAAA;EACA,mBAAA;EACA,kBAAA;ACCF;AAFA;EACE,cAAA;EACA,qBAAA;AAIF;AADA;EACE,aAAA;EACA,6BAAA;AAGF;AALA;EAKI,aAAA;AAGJ;AACA;EACE,yDAAA;EACA,sBAAA;EACA,2BAAA;EAEA,WAAA;EACA,aAAA;EACA,YAAA;EAEA,qBAAA;AADF;AAIA;EACE,uBAAA;EACA,eAAA;AAFF","sourcesContent":["@font-face {\n  font-family: 'MoonTime';\n  src: url(\"MoonTime-Regular.otf\"), url(\"MoonTime-Regular.ttf\");\n  font-weight: normal;\n  font-style: normal;\n}","@IMG_PATH: \"../../shared/img/\";\n@import \"../../fonts/MoonTime/moontime.less\";\n\n.about-link {\n  color: #000000;\n  text-decoration: none;\n}\n\n.about-links {\n  display: flex;\n  justify-content: space-evenly;\n\n  .link-icon {\n    margin: 0 5px;\n  }\n}\n\n.eloise {\n  background-image: url(\"@{IMG_PATH}eloise.jpg\");\n  background-size: cover;\n  background-position: center;\n\n  float: left;\n  height: 250px;\n  width: 170px;\n\n  margin: 20px 10px 0 0;\n}\n\n.moontime-font {\n  font-family: \"MoonTime\";\n  font-size: 50px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
